import React, { FC, PropsWithChildren } from 'react';
import styles from './Layout.module.css';
import PaykongLogo from '@/assets/paykongLogo.svg';
import cn from 'classnames';
import localFont from 'next/font/local';
import { NotificationProvider } from '@/ui/Notification/context/NotificationContext';

const font = localFont({
  src: [
    {
      path: '../../assets/fonts/SF-Pro-Display-Regular.otf',
      weight: '400',
    },
    {
      path: '../../assets/fonts/SF-Pro-Display-Semibold.otf',
      weight: '600',
    },
  ],
});

interface Props extends PropsWithChildren {}

export const Layout: FC<Props> = ({ children }) => {
  return (
    <NotificationProvider>
      <div className={cn(styles.container, font.className)}>
        <div className={styles.header}>
          <PaykongLogo />
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </NotificationProvider>
  );
};
