import React, { useState } from 'react';

import { Requisites } from 'src/shared/types';
import { Button, Text } from '@/ui';

import styles from './RequisitesWidget.module.css';
import { RequisitesWidgetCancelingModal } from './components/RequisitesWidgetCancelingModal';
import { RequisitesWidgetCredentials } from './components/RequisitesWidgetCredentials';
import { RequisitesWidgetTimer } from './components/RequisitesWidgetTimer';
import { RequisitesWidgetWarning } from './components/RequisitesWidgetWarning';
import { RequisitesWidgetPaymentAltInstruction } from './components/RequisitesWidgetPaymentAltInstruction';

interface Props {
  requisites: Requisites;
  amount: number;
  bank: {
    name: string;
    logo: string;
    currency: string;
    backgroundColors: [string, string];
  };
  onCancelPayment: () => void;
  onPaymentConfirmed: () => void;
  isPaymentCanceling: boolean;
  expiresInSeconds: number;
}

export const RequisitesWidget: React.FC<Props> = ({
  requisites,
  amount,
  bank,
  isPaymentCanceling,
  onCancelPayment,
  onPaymentConfirmed,
  expiresInSeconds,
}) => {
  const [isModalShown, setIsModalShown] = useState(false);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            Данные для перевода
            <RequisitesWidgetTimer cssClass={styles.timer} seconds={expiresInSeconds} />
          </div>
          <Text variant="title" className={styles.subTitle}>
            Выполняйте перевод одним платежом и нажимайте кнопку &quot;Я оплатил&quot;
          </Text>
          <RequisitesWidgetPaymentAltInstruction />
          <RequisitesWidgetCredentials requisites={requisites} amount={amount} bank={bank} />
          <RequisitesWidgetWarning />
          <div className={styles.buttons}>
            <Button onClick={() => setIsModalShown(true)} color="danger">
              Отменить
            </Button>
            <Button onClick={onPaymentConfirmed}>Я оплатил</Button>
          </div>
        </div>
      </div>
      <RequisitesWidgetCancelingModal
        isShown={isModalShown}
        isPaymentCanceling={isPaymentCanceling}
        onCancel={() => setIsModalShown(false)}
        onCancelPayment={async () => {
          await onCancelPayment();

          setIsModalShown(false);
        }}
      />
    </>
  );
};
