import React from 'react';
import cn from 'classnames';

import { Notification } from './types/notification.inferface';
import styles from './NotificationList.module.css';

interface Props {
  notifications: Notification[];
}

export const NotificationList: React.FC<Props> = ({ notifications }) => {
  return (
    <div className={styles.container}>
      {notifications.map((notif) => (
        <div key={notif.id} className={cn(styles.notification, styles[notif.type] || '')}>
          {notif.message}
        </div>
      ))}
    </div>
  );
};
