import React from 'react';

import styles from '../RequisitesWidgetPaymentAltInstructionComponent.module.css';

export const RequisitesWidgetPaymentAltInstructionTbankCard = () => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>Шаг первый:</p>
      <ul className={styles.list}>
        <li className={styles.item}>1. Откройте приложение Т-банка</li>
        <li className={styles.item}>2. В разделе &quot;Платежи&quot; выберите пункт &quot;По номеру карты&quot;</li>
      </ul>
      <img src="/instructions/tbank/1-payments-by-card-1.webp" width="auto" className={styles.img} />
      <img src="/instructions/tbank/1-payments-by-card-2.webp" width="auto" className={styles.img} />
      <p className={styles.title}>Шаг второй:</p>
      <ul className={styles.list}>
        <li className={styles.item}>1. Вставьте (введите) номер карты получателя</li>
        <li className={styles.item}>2. Вставьте (введите) ФИО получателя</li>
      </ul>
      <img src="/instructions/tbank/2-enter-number-card.webp" width="auto" className={styles.img} />
      <img src="/instructions/tbank/2-enter-fio.webp" width="auto" className={styles.img} />
      <p className={styles.title}>Шаг третий:</p>
      <ul className={styles.list}>
        <li className={styles.item}>1. Выберите банк получателя</li>
        <li className={styles.item}>
          2. Вставьте (введите) точную сумму перевода в рублях, которую вы вводили при создании депозита
        </li>
        <li className={styles.item}>3. Нажмите кнопку &quot;перевести&quot;</li>
      </ul>
      <img src="/instructions/tbank/3-enter-summ-by-card.webp" width="auto" className={styles.img} />
      <img src="/instructions/tbank/3-click-transfer.webp" width="auto" className={styles.img} />
      <p className={styles.finalText}>Готово!</p>
    </div>
  );
};
