import { FC } from 'react';
import { ProgressBar, Spinner, Text } from '@/ui';
import styles from './RequisitesSearchWidget.module.css';
import { ContentBox } from '@/ui/ContentBox';
import { useTimer } from '@/shared/hooks/useTimer.hook';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

interface Props {
  expiresAt: string | null;
}

export const RequisitesSearchWidget: FC<Props> = ({ expiresAt }) => {
  const timerDuration = expiresAt && dayjs(expiresAt).subtract(dayjs().millisecond(), 'milliseconds').second();

  const timeLeft = useTimer(timerDuration || 30);

  return (
    <div className={styles.container}>
      <ProgressBar steps={['', '', 'Поиск реквизитов', '']} current={2} className={styles.progressBar} />
      <ContentBox width="85svw">
        <Text variant="title" textAlign="center">
          Ищем для вас реквизиты...
        </Text>
        <Spinner />
        <Text variant="title.1.medium" color="secondary">
          {dayjs.duration(timeLeft, 'seconds').format('mm:ss')}
        </Text>
      </ContentBox>
    </div>
  );
};
