import React from 'react';
import cn from 'classnames';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import styles from './RequisitesWidgetCountdownTimer.module.css';
import { useTimer } from '@/shared/hooks/useTimer.hook';
import { formatTime } from 'src/shared/utils';

dayjs.extend(duration);

interface Props {
  seconds: number;
  cssClass: string;
}

export const RequisitesWidgetTimer: React.FC<Props> = ({ seconds, cssClass }) => {
  const secondsLeft = useTimer(seconds);

  return (
    <div className={cn(styles.timer, cssClass)}>
      <h1>{formatTime(secondsLeft)}</h1>
    </div>
  );
};
