import { FC, PropsWithChildren } from 'react';
import styles from './GhostButton.module.css';

interface Props {
  onClick: () => void;
}

export const GhostButton: FC<Props & PropsWithChildren> = ({ children, onClick }) => {
  return (
    <div className={styles.container} onClick={onClick}>
      {children}
    </div>
  );
};
