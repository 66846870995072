import { FC, PropsWithChildren } from 'react';
import cn from 'classnames';
import styles from './Button.module.css';

interface Props extends PropsWithChildren {
  disabled?: boolean;
  color?: 'primary' | 'gray' | 'danger' | 'none';
  type?: 'filled' | 'outlined';
  onClick?: () => void;
}

export const Button: FC<Props> = ({ children, disabled, color = 'primary', type = 'filled', onClick }) => {
  return (
    <div
      className={cn(
        styles.container,
        disabled && styles.disabled,
        color === 'primary' && styles.primary,
        color === 'gray' && styles.gray,
        color === 'danger' && styles.danger,
        type === 'outlined' && styles.outline
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
