import { Button, Text } from '@/ui';
import styles from '@/shared/styles/StatusWidgetStyles.module.css';
import ErrorExclamationMark from '@/assets/errorExclamationMark.svg';
import { useRouter } from 'next/router';

interface Props {
  redirectUrl?: string | null;
}

export const PaymentCanceledWidget: React.FC<Props> = ({ redirectUrl }) => {
  const router = useRouter();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.imgContainer}>
          <ErrorExclamationMark />
        </div>
        <div className={styles.titleWrapper}>
          <Text variant="title.1">Платеж отменен</Text>
        </div>
        {redirectUrl && (
          <div className={styles.buttonContainer}>
            <Button onClick={() => router.replace(redirectUrl)}>В магазин</Button>
          </div>
        )}
      </div>
    </div>
  );
};
