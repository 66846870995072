import { FC } from 'react';
import { ContentBox, Spinner, Text } from '@/ui';

interface Props {
  text?: string;
}

export const LoadingWidget: FC<Props> = ({ text }) => {
  return (
    <ContentBox width="85svw">
      {text && (
        <Text variant="title.1" style={{ marginBottom: '16px' }}>
          {text}
        </Text>
      )}
      <Spinner />
    </ContentBox>
  );
};
