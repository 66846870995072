import React, { useState } from 'react';
import cn from 'classnames';

import { Modal, Text } from '@/ui';
import { PaymentMethodEnum } from 'src/shared/types';
import CloseIcon from '@/assets/closeIcon.svg';
import InfoIcon from '@/assets/info-icon.svg';

import styles from './RequisitesWidgetPaymentInstruction.module.css';

interface Props {
  requisitesType: PaymentMethodEnum;
}

export const RequisitesWidgetPaymentInstruction: React.FC<Props> = ({ requisitesType }) => {
  const [isModalShown, setIsModalShown] = useState(false);

  const headerTitleMap = {
    [PaymentMethodEnum.CARD]: 'Инструкция по оплате на карту',
    [PaymentMethodEnum.ACCOUNT]: 'Инструкция по оплате на счёт',
    [PaymentMethodEnum.PHONE]: 'Инструкция по оплате по СБП',
  };

  return (
    <>
      <Modal isShown={isModalShown}>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <Text variant="title">{headerTitleMap[requisitesType]}</Text>
            <CloseIcon className={cn(styles.button, styles.buttonClose)} onClick={() => setIsModalShown(false)} />
          </div>
          <Text className={styles.instructionText} variant="caption.2">
            {PaymentMethodEnum.CARD === requisitesType && (
              <>
                <div>1. Скопируйте номер карты получателя</div>
                <div>2. Откройте приложение своего банка</div>
                <div>3. В форме банка для перевода вставьте скопированный номер карты</div>
                <div>4. Введите и проверьте сумму</div>
                <div>5. Отправьте перевод</div>
                <div>6. На текущей странице нажмите кнопку &quot;Я оплатил&quot;</div>
                <div>7. Ожидайте подтверждения платежа</div>
              </>
            )}
            {PaymentMethodEnum.ACCOUNT === requisitesType && (
              <>
                <div>1. Скопируйте номер счёта получателя</div>
                <div>2. Откройте приложение своего банка</div>
                <div>3. В форме банка для перевода вставьте скопированный номер счёта</div>
                <div>4. Введите и проверьте сумму</div>
                <div>5. Отправьте перевод</div>
                <div>6. На текущей странице нажмите кнопку &quot;Я оплатил&quot;</div>
                <div>7. Ожидайте подтверждения платежа</div>
              </>
            )}
            {PaymentMethodEnum.PHONE === requisitesType && (
              <>
                <div>1. Скопируйте номер телефона получателя</div>
                <div>2. Откройте приложение своего банка</div>
                <div>3. В форме банка для перевода вставьте скопированный номер телефона</div>
                <div>4. Введите и проверьте сумму</div>
                <div>5. Выберите указанный банк</div>
                <div>6. Отправьте перевод</div>
                <div>7. На текущей странице нажмите кнопку &quot;Я оплатил&quot;</div>
                <div>8. Ожидайте подтверждения платежа</div>
              </>
            )}
          </Text>
        </div>
      </Modal>
      <div className={styles.button} onClick={() => setIsModalShown(true)}>
        <InfoIcon stroke="white" />
      </div>
    </>
  );
};
