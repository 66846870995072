import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './Modal.module.css';
import cn from 'classnames';
import localFont from 'next/font/local';

const font = localFont({
  src: [
    {
      path: '../../assets/fonts/SF-Pro-Display-Regular.otf',
      weight: '400',
    },
    {
      path: '../../assets/fonts/SF-Pro-Display-Semibold.otf',
      weight: '600',
    },
  ],
});

interface Props extends PropsWithChildren {
  isShown: boolean;
  onClose?: () => void;
}

export const Modal: FC<Props> = ({ children, isShown, onClose }) => {
  const [isRendered, setIsRendered] = useState<boolean>(false);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  if (!isRendered || !isShown) {
    return null;
  }

  return createPortal(
    <div
      className={cn(styles.wrapper, font.className)}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose?.();
        }
      }}
    >
      <div className={styles.bodyContainer}>
        <div className={styles.scrollableContent}>{children}</div>
      </div>
    </div>,
    document.body
  );
};
