import { FC, useMemo } from 'react';
import { Button, ContentBox, GhostButton, Text } from '@/ui';
import styles from './RequisitesNotFoundWidget.module.css';
import ArrowLeftIcon from '@/assets/arrowLeft.svg';
import { useTimer } from '@/shared/hooks/useTimer.hook';
import { formatAmount } from 'src/shared/utils';

interface Props {
  preorderId: number;
  hideRetry?: boolean;
  proposedAmount: number;
  requestedAmount: number;
  onRequestedAmountProcessClick: () => void;
  onProposedAmountProcessClick: () => void;
  onCancelClick: () => void;
  currencyCode: string;
  onRetry: () => void;
}

export const RequisitesNotFoundWidget: FC<Props> = ({
  hideRetry,
  proposedAmount,
  requestedAmount,
  currencyCode,
  onRequestedAmountProcessClick,
  onProposedAmountProcessClick,
  onCancelClick,
  onRetry,
  preorderId,
}) => {
  const shouldAutoRetry = useMemo(() => {
    return !localStorage.getItem(`autoretried-${preorderId}`);
  }, []);

  const savedSeconds = useMemo(() => {
    return Number(localStorage.getItem(`retry-timer-${preorderId}`) ?? 30);
  }, []);

  const secondsLeft = useTimer(savedSeconds, {
    onTimerEnd: () => {
      if (!shouldAutoRetry) {
        return;
      }

      localStorage.setItem(`autoretried-${preorderId}`, 'true');
      localStorage.removeItem(`retry-timer-${preorderId}`);
      onRequestedAmountProcessClick();
    },
    onTick: (secondsLeft) => localStorage.setItem(`retry-timer-${preorderId}`, String(secondsLeft)),
  });

  return (
    <>
      <ContentBox>
        <div className={styles.titleContainer}>
          <Text variant="title">Нет свободных реквизитов</Text>
          <Text variant="caption.2">
            На данный момент нет свободных реквизитов для оплаты на {formatAmount(requestedAmount, currencyCode)}
          </Text>
        </div>
        <div className={styles.amountContainer}>
          <div className={styles.amountContainerTitle}>Можем предложить оплатить:</div>
          <div className={styles.amountContainerAmount}>{formatAmount(proposedAmount, currencyCode)}</div>
        </div>
        <div className={styles.buttonsContainer}>
          <Button onClick={onProposedAmountProcessClick}>Оплатить {formatAmount(proposedAmount, currencyCode)}</Button>
          <div className={styles.buttonsRow}>
            {!hideRetry && (
              <Button color="gray" onClick={onRequestedAmountProcessClick}>
                Оплатить {formatAmount(requestedAmount, currencyCode)}
              </Button>
            )}
            <Button color="danger" onClick={onCancelClick}>
              Отменить
            </Button>
          </div>
          {shouldAutoRetry && (
            <Text variant="caption.2">
              Через {secondsLeft}с попробуем снова подобрать реквизиты на {formatAmount(requestedAmount, currencyCode)}
            </Text>
          )}
        </div>
      </ContentBox>
      <GhostButton onClick={onRetry}>
        <ArrowLeftIcon />
        Назад к выбору способа оплаты
      </GhostButton>
    </>
  );
};
