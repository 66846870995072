import { FC } from 'react';
import { Text } from '@/ui';
import styles from './PageNotFoundWidget.module.css';

export const PageNotFoundWidget: FC = () => {
  return (
    <div className={styles.container}>
      <Text variant="title">Страница не найдена</Text>
    </div>
  );
};
