import React from 'react';

import styles from '../RequisitesWidgetPaymentAltInstructionComponent.module.css';

export const RequisitesWidgetPaymentAltInstructionSberPhone = () => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>Шаг первый:</p>
      <ul className={styles.list}>
        <li className={styles.item}>1. Откройте приложение Сбера</li>
        <li className={styles.item}>2. В разделе &quot;Платежи&quot; выберите пункт &quot;В другую страну&quot;</li>
      </ul>
      <img src="/instructions/sber/1-payments.webp" width="auto" className={styles.img} />
      <img src="/instructions/sber/2-country.webp" width="auto" className={styles.img} />
      <p className={styles.title}>Шаг второй:</p>
      <ul className={styles.list}>
        <li className={styles.item}>1. В списке стран выберите необходимую страну (либо воспользуйтесь поиском)</li>
        <img src="/instructions/sber/3-choose-country.webp" width="auto" className={styles.img} />
        <li className={styles.item}>2. В открывшемся окне выберите способ зачисления: &quot;Телефон&quot; </li>
        <img src="/instructions/sber/4-choose-phone.webp" width="auto" className={styles.img} />
        <li className={styles.item}>3. Введите реквизиты получателя (номер телефона)</li>
      </ul>
      <p className={styles.note}>Для копирования реквизитов получателя нажмите на иконку справа.</p>
      <img src="/instructions/sber/5-phone-requisites.webp" width="auto" className={styles.img} />
      <p className={styles.title}>Шаг третий:</p>
      <ul className={styles.list}>
        <li className={styles.item}>В списке банков выберите банк получателя</li>
      </ul>
      <img src="/instructions/sber/6-phone-choose-bank.webp" width="auto" className={styles.img} />
      <p className={styles.title}>Шаг четвертый:</p>
      <ul className={styles.list}>
        <li className={styles.item}>1. Нажмите на иконку справа, чтобы скопировать сумму зачисления</li>
        <li className={styles.item}>2. Вставьте скопированную сумму и нажмите &quot;Продолжить&quot;</li>
        <img src="/instructions/sber/7-phone-next-step.webp" width="auto" className={styles.img} />
        <li className={styles.item}>3. Нажмите &quot;Перевести [сумма]&quot;, чтобы отправить денежные средства</li>
      </ul>
      <img src="/instructions/sber/8-phone-transfer.webp" width="auto" className={styles.img} />
      <p className={styles.finalText}>Готово!</p>
    </div>
  );
};
