import { FC, PropsWithChildren, ReactElement } from 'react';
import styles from './BigIconButton.module.css';
import { Text } from '@/ui/Text';
import cn from 'classnames';
import JumboIcon from '@/assets/jumbo.svg';

interface Props extends PropsWithChildren {
  icon: ReactElement;
  selected?: boolean;
  onClick: () => void;
  disabled?: boolean;
  jumbo?: boolean;
}

export const BigIconButton: FC<Props> = ({ children, icon, selected = false, onClick, disabled, jumbo }) => {
  return (
    <div
      onClick={() => !disabled && onClick()}
      className={cn(styles.container, selected && styles.selected, disabled && styles.disabled)}
    >
      {jumbo && <JumboIcon className={styles.jumbo} />}
      <div>{icon}</div>
      <Text variant="caption.1.semibold" color="primary.light">
        {children}
      </Text>
    </div>
  );
};
