import React, { useState } from 'react';

import styles from './RequisitesWidgetPaymentAltInstruction.module.css';
import { Modal, Text } from '@/ui';
import CloseIcon from '@/assets/closeIcon.svg';
import cn from 'classnames';
import { RequisitesWidgetPaymentAltInstructionSberCard } from './components/SberCard/RequisitesWidgetPaymentAltInstructionSberCard';
import { RequisitesWidgetPaymentAltInstructionSberPhone } from './components/SberPhone/RequisitesWidgetPaymentAltInstructionSberPhone';
import { RequisitesWidgetPaymentAltInstructionTbankPhone } from './components/TbankPhone/RequisitesWidgetPaymentAltInstructionTbankPhone';
import { RequisitesWidgetPaymentAltInstructionTbankCard } from './components/TbankCard/RequisitesWidgetPaymentAltInstructionTbankCard';

enum InstructionType {
  SBER_PHONE = 'SBER_PHONE',
  SBER_CARD = 'SBER_CARD',
  TBANK_PHONE = 'TBANK_PHONE',
  TBANK_CARD = 'TBANK_CARD',
}

export const RequisitesWidgetPaymentAltInstruction: React.FC = () => {
  const [isModalShown, setIsModalShown] = useState(false);
  const [instructionType, setInstructionType] = useState<InstructionType | null>(null);

  const headerTitleMap = {
    [InstructionType.SBER_CARD]: 'Инструкция по оплате со Сбера (по номеру карты)',
    [InstructionType.SBER_PHONE]: 'Инструкция по оплате со Сбера (по номеру телефона)',
    [InstructionType.TBANK_CARD]: 'Инструкция по оплате с Т-банка (по номеру карты)',
    [InstructionType.TBANK_PHONE]: 'Инструкция по оплате с Т-банка (по номеру телефона)',
  };

  function renderContent(type: InstructionType) {
    setInstructionType(type);

    setIsModalShown(true);
  }

  function getInstructionComponent() {
    switch (instructionType) {
      case InstructionType.SBER_PHONE:
        return <RequisitesWidgetPaymentAltInstructionSberPhone />;
      case InstructionType.SBER_CARD:
        return <RequisitesWidgetPaymentAltInstructionSberCard />;
      case InstructionType.TBANK_PHONE:
        return <RequisitesWidgetPaymentAltInstructionTbankPhone />;
      case InstructionType.TBANK_CARD:
        return <RequisitesWidgetPaymentAltInstructionTbankCard />;
      default:
        return null;
    }
  }

  return (
    <>
      <Modal isShown={isModalShown} onClose={() => setIsModalShown(false)}>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            {instructionType && (
              <Text style={{ paddingRight: 32 }} variant="title">
                {headerTitleMap[instructionType]}
              </Text>
            )}
            <CloseIcon className={cn(styles.button, styles.buttonClose)} onClick={() => setIsModalShown(false)} />
          </div>
          {getInstructionComponent()}
        </div>
      </Modal>
      <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 16, columnGap: 8 }}>
        <div onClick={() => renderContent(InstructionType.SBER_PHONE)} className={styles.instructionsButton}>
          Сбер (номер)
        </div>
        <div onClick={() => renderContent(InstructionType.SBER_CARD)} className={styles.instructionsButton}>
          Сбер (карта)
        </div>
        <div onClick={() => renderContent(InstructionType.TBANK_PHONE)} className={styles.instructionsButton}>
          Т-БАНК (номер)
        </div>
        <div onClick={() => renderContent(InstructionType.TBANK_CARD)} className={styles.instructionsButton}>
          Т-БАНК (карта)
        </div>
      </div>
    </>
  );
};
