import styles from '@/shared/styles/StatusWidgetStyles.module.css';
import { Spinner, Text } from '@/ui';
import widgetStyles from '@/widgets/PaymentConfirmationWidget/PaymentConfirmationWidget.module.css';

export const PaymentConfirmationWidget: React.FC = () => {
  return (
    <div className={widgetStyles.columnFlexContainer}>
      <div className={styles.titleWrapper}>
        <Text variant="title">Подтверждение...</Text>
      </div>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={widgetStyles.spinnerWrapper}>
            <Spinner />
          </div>
          <div style={{ paddingBottom: 16 }} className={styles.titleWrapper}>
            <Text color="description" variant="caption.2">
              Выполняется поиск платежа для подтверждения
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};
