import { FC } from 'react';
import { Text } from '@/ui';
import styles from './PageErrorWidget.module.css';

export const PageErrorWidget: FC = () => {
  return (
    <div className={styles.container}>
      <Text variant="title">Произошла ошибка</Text>
      <Text variant="title.1">Попробуйте перезагрузить страницу или обратитесь в поддержку</Text>
    </div>
  );
};
