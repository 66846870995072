import Image from 'next/image';
import { useState } from 'react';
import { Button, Text } from '@/ui';
import { useNotification } from '@/ui/Notification/context/NotificationContext';
import styles from '@/widgets/ReceiptUploadWidget/ReceiptUploadWidget.module.css';
import { useLogService } from '@/shared/hooks/useLogService.hook';
import PdfIcon from '@/assets/pdfIcon.svg';

interface Props {
  onCreateDispute: (comment: string, file: File) => void;
}

export const ReceiptUploadWidget: React.FC<Props> = ({ onCreateDispute }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [inputArea, setInputArea] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');
  const [fileError, setFileError] = useState<string>('');
  const [isDragging, setIsDragging] = useState(false);
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const logger = useLogService('DisputePage');

  const { addNotification } = useNotification();

  function processFile(file: File) {
    const fileSizeLimit = 1.8 * 1024 * 1024;

    if (file.size <= fileSizeLimit) {
      setSelectedFile(file);
      setFileName(file.name);
      setFileError('');

      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result as string);
        };
        reader.readAsDataURL(file);
      } else {
        setImagePreview(null);
      }
    } else {
      setFileError('Файл слишком большой (максимальный размер - 1.8 МБ)');
      setFileName('');
      setImagePreview(null);
    }
  }

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if (!file) return;

    processFile(file);
  }

  function handleDeleteFile() {
    setSelectedFile(null);
    setFileName('');
    setFileError('');
    setImagePreview(null);
  }

  function handleCommentChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    const value = event.target.value;
    if (value.length <= 500) {
      setInputArea(value);
    }
  }

  async function handleSubmit() {
    if (!selectedFile) {
      setFileError('Вы не загрузили файл');
      return;
    }

    try {
      onCreateDispute(inputArea, selectedFile!);
    } catch (error) {
      logger.error(error);
      addNotification('Произошла ошибка при отправке обращения!', 'error');
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div>
          <div className={styles.titleWrapper}>
            <Text variant="title.1">Выберите файл для загрузки</Text>
          </div>
          <div className={styles.rowFlexContainer}>
            <div className={styles.inputWrapper}>
              {!fileError ? (
                <Text variant="caption.1.semibold">Файл:</Text>
              ) : (
                <div>
                  <Text style={{ color: 'red' }} variant="caption.1.semibold">
                    {fileError}
                  </Text>
                </div>
              )}
              <div className={`${styles.inputContainer} ${isDragging ? styles.dragging : ''}`}>
                <div className={styles.fileInfo}>
                  {selectedFile &&
                    (selectedFile.type === 'application/pdf' ? (
                      <PdfIcon className={styles.fileIcon} />
                    ) : (
                      imagePreview && (
                        <Image
                          src={imagePreview}
                          alt="preview"
                          className={styles.imagePreview}
                          width={24}
                          height={24}
                        />
                      )
                    ))}
                  <input
                    disabled
                    value={fileName}
                    onDragOver={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsDragging(true);
                    }}
                    onDragLeave={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsDragging(false);
                    }}
                    onDrop={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsDragging(false);

                      const file = e.dataTransfer.files[0];
                      if (file) {
                        processFile(file);
                      }
                    }}
                    placeholder="до 1.8 мб"
                  ></input>
                </div>
                {fileName && (
                  <div className={styles.deleteButton} onClick={handleDeleteFile} aria-label="Удалить файл">
                    ✕
                  </div>
                )}
              </div>
            </div>
            <div className={styles.columnContainer}>
              <label htmlFor="file-upload">
                <Button color="gray">Выбрать</Button>
              </label>
              <input
                id="file-upload"
                type="file"
                accept=".png, .jpg, .jpeg, .pdf"
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
              <div style={{ fontSize: 12, fontWeight: 400, color: '#979BB0', textAlign: 'center' }}>
                Поддерживаемые форматы png, jpg, pdf
              </div>
            </div>
          </div>
          <div className={styles.inputWrapper}>
            <Text variant="caption.1.semibold">Комментарий:</Text>
            <div className={styles.inputContainer}>
              <textarea value={inputArea} onChange={handleCommentChange} className={styles.textarea}></textarea>
            </div>
            <div className={styles.maxSymbols}>{inputArea.length}/500</div>
          </div>
          <div className={styles.buttonContainer}>
            <Button onClick={handleSubmit}>Отправить</Button>
          </div>
        </div>
      </div>
    </div>
  );
};
