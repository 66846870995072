import React, { useState } from 'react';
import styles from './SelectPaymentMethodWidget.module.css';
import ChoosePaymentAccount from '@/assets/choosePaymentAccount.svg';
import ChoosePaymentCardIcon from '@/assets/choosePaymentCard.svg';
import ChoosePaymentSBP from '@/assets/choosePaymentSBP.svg';
import Cards from '@/assets/cards.svg';
import ArrowRight from '@/assets/arrowRight.svg';
import { BigIconButton, Button, ProgressBar, Text } from '@/ui';
import cn from 'classnames';
import { PaymentMethodEnum } from 'src/shared/types';
import { formatToLocaleString } from 'src/shared/utils';
import { FetchPreorderConfigRequestResponse } from '@/server/api';
// import { formatAmount } from '@/utils/formatAmount';
//
// const optionsAmount: Record<PaymentMethodEnum, number> = {
//   [PaymentMethodEnum.CARD]: 20000,
//   [PaymentMethodEnum.ACCOUNT]: 30000,
//   [PaymentMethodEnum.PHONE]: 10000,
// };

interface Props {
  amount: number | null;
  currencyCode: string;
  onConfirm: (requisitesType: PaymentMethodEnum, amount: number) => void;
  config: FetchPreorderConfigRequestResponse;
}

export const SelectPaymentMethodWidget: React.FC<Props> = ({ amount, onConfirm, config }) => {
  const [selectedOption, setSelectedOption] = useState<PaymentMethodEnum>();
  const [inputValue, setInputValue] = useState<string>(String(amount));

  const cardsDisabled = config.requisites.find(({ type }) => type === PaymentMethodEnum.CARD)?.banks?.length === 0;

  return (
    <>
      <ProgressBar current={0} steps={['Выбор способа', 'Выбор банка', 'Поиск реквизитов', 'Реквизиты']} />
      <div className={styles.container}>
        <div className={styles.paymentContainer}>
          <Text style={{ color: 'black', paddingBottom: 16 }} variant="title">
            Выберите способ оплаты
          </Text>
          <div style={{ display: 'flex', flexDirection: 'row', gap: 8, flexWrap: 'wrap' }}>
            <BigIconButton
              disabled={cardsDisabled}
              jumbo
              selected={selectedOption === PaymentMethodEnum.CARD}
              icon={<ChoosePaymentCardIcon />}
              onClick={() => setSelectedOption(PaymentMethodEnum.CARD)}
            >
              По номеру карты
            </BigIconButton>
            <BigIconButton
              jumbo
              selected={selectedOption === PaymentMethodEnum.ACCOUNT}
              icon={<ChoosePaymentAccount />}
              onClick={() => setSelectedOption(PaymentMethodEnum.ACCOUNT)}
            >
              По номеру счета
            </BigIconButton>
            <BigIconButton
              selected={selectedOption === PaymentMethodEnum.PHONE}
              icon={<ChoosePaymentSBP />}
              onClick={() => setSelectedOption(PaymentMethodEnum.PHONE)}
            >
              По номеру телефона
            </BigIconButton>
          </div>
        </div>
        <div className={styles.amountContainer}>
          <Text style={{ paddingBottom: 4 }} variant="title">
            Сумма перевода
          </Text>
          <Text color="description" variant="caption.1">
            Сумма перевода станет доступной после выбора способа оплаты
          </Text>
          <div className={cn(styles.amountWrapper, !selectedOption && styles.disabled)}>
            <div className={styles.inputContainer}>
              <Cards />
              <input
                value={inputValue}
                onChange={(e) => setInputValue(formatToLocaleString(e.target.value))}
                disabled={!selectedOption || true}
                className={cn(styles.input, styles.disabled)}
                placeholder="Сумма перевода"
              />
            </div>
            {/*<Text color="description" variant="caption.2">*/}
            {/*  Минимальная сумма оплаты - {selectedOption && formatAmount(optionsAmount[selectedOption], currencyCode)}*/}
            {/*</Text>*/}
            {/*<div style={{ display: 'flex', padding: 20, columnGap: 10 }}>*/}
            {/*  {['10 000', '15 000', '50 000', '100 000'].map((value) => (*/}
            {/*    <Button*/}
            {/*      key={value}*/}
            {/*      onClick={() => selectedOption && setInputValue(formatToLocaleString(value))}*/}
            {/*      type="outlined"*/}
            {/*    >*/}
            {/*      {value}*/}
            {/*    </Button>*/}
            {/*  ))}*/}
            {/*</div>*/}
            <div style={{ paddingTop: 8 }}>
              <Button disabled={!selectedOption} onClick={() => onConfirm(selectedOption!, Number(inputValue))}>
                Далее
                <ArrowRight />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
