import cn from 'classnames';
import React from 'react';

import CloseIcon from '@/assets/closeIcon.svg';
import { Button, Modal, Spinner, Text } from '@/ui';

import styles from './RequisitesWidgetCancelingModal.module.css';

interface Props {
  isShown: boolean;
  isPaymentCanceling: boolean;
  onCancel: () => void;
  onCancelPayment: () => void;
}

export const RequisitesWidgetCancelingModal: React.FC<Props> = ({
  isShown,
  isPaymentCanceling,
  onCancel,
  onCancelPayment,
}) => {
  return (
    <Modal isShown={isShown}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Text variant="title">Отмена платежа</Text>
          <CloseIcon className={cn(styles.button, styles.buttonClose)} onClick={onCancel} />
        </div>
        {isPaymentCanceling ? (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        ) : (
          <div>
            <Text className={styles.instructionText} variant="caption.2">
              Вы уверены что хотите отменить платеж?
            </Text>
            <div className={styles.buttons}>
              <Button onClick={onCancelPayment} color="danger">
                Да
              </Button>
              <Button onClick={onCancel}>Нет</Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
