import { Button, Text } from '@/ui';
import styles from '@/shared/styles/StatusWidgetStyles.module.css';
import CheckedGreenIcon from '@/assets/checkedGreenIcon.svg';
import { useRouter } from 'next/router';

interface Props {
  redirectUrl?: string | null;
}

export const PaymentReceivedWidget: React.FC<Props> = ({ redirectUrl }) => {
  const router = useRouter();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.imgContainer}>
          <CheckedGreenIcon />
        </div>
        <div className={styles.titleWrapper}>
          <Text variant="title.1">Отлично!</Text>
          <Text color="description" variant="caption.2">
            Платеж успешно получен
          </Text>
        </div>
        {redirectUrl && (
          <div className={styles.buttonContainer}>
            <Button onClick={() => router.replace(redirectUrl)}>В магазин</Button>
          </div>
        )}
      </div>
    </div>
  );
};
