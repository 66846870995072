import React from 'react';

import styles from '../RequisitesWidgetPaymentAltInstructionComponent.module.css';

export const RequisitesWidgetPaymentAltInstructionTbankPhone = () => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>Шаг первый:</p>
      <ul className={styles.list}>
        <li className={styles.item}>1. Откройте приложение Т-банка</li>
        <li className={styles.item}>
          2. В разделе &quot;Платежи&quot; выберите пункт &quot;Переводы по телефону&quot;
        </li>
      </ul>
      <img src="/instructions/tbank/1-payments.webp" width="auto" className={styles.img} />
      <img src="/instructions/tbank/1-payments-by-phone.webp" width="auto" className={styles.img} />
      <p className={styles.title}>Шаг второй:</p>
      <ul className={styles.list}>
        <li className={styles.item}>1. Выберите страну получателя</li>
        <li className={styles.item}>2. Укажите номер телефона и выберите получателя перевода</li>
      </ul>
      <img src="/instructions/tbank/2-choose-country.webp" width="auto" className={styles.img} />
      <img src="/instructions/tbank/2-recipient.webp" width="auto" className={styles.img} />
      <p className={styles.title}>Шаг третий:</p>
      <ul className={styles.list}>
        <li className={styles.item}>1. Выберите банк получателя</li>
        <li className={styles.item}>
          2. Введите точную сумму перевода в рублях, которую вы вводили при создании депозита
        </li>
        <li className={styles.item}>3. Нажмите кнопку &quot;перевести&quot;</li>
      </ul>
      <img src="/instructions/tbank/3-enter-summ.webp" width="auto" className={styles.img} />
      <p className={styles.finalText}>Готово!</p>
    </div>
  );
};
