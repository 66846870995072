import { FC } from 'react';
import styles from './RequisitesSearchFailureWidget.module.css';
import ErrorExclamationMark from '@/assets/errorExclamationMark.svg';
import { Button, Text } from '@/ui';
import { ContentBox } from '@/ui/ContentBox';
import { useRouter } from 'next/router';

interface Props {
  noRetry?: boolean;
  redirectUrl: string;
  onRetry?: () => void;
  title?: string;
}

export const RequisitesSearchFailureWidget: FC<Props> = ({ noRetry = false, redirectUrl, onRetry, title }) => {
  const router = useRouter();

  return (
    <ContentBox>
      <Text variant="title" textAlign="center">
        {title ?? 'Нет свободных реквизитов'}
      </Text>
      <ErrorExclamationMark />
      <div className={styles.buttonsContainer}>
        {!noRetry && <Button onClick={onRetry}>Вернуться к выбору способа оплаты</Button>}
        <Button color="danger" onClick={() => router.replace(redirectUrl)}>
          В магазин
        </Button>
      </div>
    </ContentBox>
  );
};
