import Image from 'next/image';
import React, { useMemo } from 'react';

import CopyIcon from '@/../public/copy-icon.svg';
import { useNotification } from '@/ui/Notification/context/NotificationContext';
import { formatAccountNumber, formatAmount, formatCardNumber, formatPhoneNumber } from 'src/shared/utils';

import { Requisites, PaymentMethodEnum } from 'src/shared/types';
import { RequisitesWidgetPaymentInstruction } from '../RequisitesWidgetPaymentInstruction';
import styles from './RequisitesWidgetCredentials.module.css';

interface Props {
  requisites: Requisites;
  amount: number;
  bank: {
    name: string;
    logo: string;
    currency: string;
    backgroundColors: [string, string];
  };
}

export const RequisitesWidgetCredentials: React.FC<Props> = ({ bank, requisites, amount }) => {
  const { addNotification } = useNotification();

  const requisitesLabel = useMemo(() => {
    return {
      [PaymentMethodEnum.CARD]: 'Номер карты',
      [PaymentMethodEnum.ACCOUNT]: 'Счёт',
      [PaymentMethodEnum.PHONE]: 'Номер телефона',
    }[requisites.type];
  }, [requisites.type]);

  const requisitesFormattedValue = useMemo(() => {
    switch (requisites.type) {
      case PaymentMethodEnum.PHONE:
        return formatPhoneNumber(requisites.value);
      case PaymentMethodEnum.CARD:
        return formatCardNumber(requisites.value);
      case PaymentMethodEnum.ACCOUNT:
        return formatAccountNumber(requisites.value);
    }
  }, [requisites.type, requisites.value]);

  const gradientStyle = {
    background: `linear-gradient(99deg, ${bank.backgroundColors[0]} 0%, ${bank.backgroundColors[1]} 100%)`,
  };

  async function handleRequisitesCopy(label: string, value: string): Promise<void> {
    try {
      addNotification(label, 'success');

      await navigator.clipboard.writeText(value);
    } catch {
      addNotification('Не удалось скопировать', 'error');
    }
  }

  return (
    <>
      <div style={gradientStyle} className={styles.credentials} data-bank={bank.name}>
        <Image src={bank.logo} alt="Bank Logo" width={44} height={44} style={{ objectFit: 'contain' }} />
        <RequisitesWidgetPaymentInstruction requisitesType={requisites.type} />
        <div className={styles.credentialsTextWrapper}>
          <div className={styles.credentialsText}>{requisitesLabel}:</div>
          <div className={styles.credentialsText}>{requisitesFormattedValue}</div>
        </div>
        <div
          className={styles.button}
          onClick={() => handleRequisitesCopy(requisitesLabel + ' успешно скопирован!', requisites.value)}
        >
          <CopyIcon />
        </div>
        <div className={styles.credentialsTextWrapper}>
          <div className={styles.credentialsText}>Сумма:</div>
          <div className={styles.credentialsText}>{formatAmount(amount, bank.currency)}</div>
        </div>
        <div
          className={styles.button}
          onClick={() => handleRequisitesCopy('Сумма успешно скопирована!', String(amount))}
        >
          <CopyIcon />
        </div>
      </div>
    </>
  );
};
