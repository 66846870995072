import { Button, Text } from '@/ui';
import styles from '@/shared/styles/StatusWidgetStyles.module.css';
import ErrorExclamationMark from '@/assets/errorExclamationMark.svg';
import { useRouter } from 'next/router';

interface Props {
  redirectUrl?: string | null;
  orderUuid?: string;
}

export const PaymentNotReceivedWidget: React.FC<Props> = ({ redirectUrl, orderUuid }) => {
  const router = useRouter();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.imgContainer}>
          <ErrorExclamationMark />
        </div>
        <div className={styles.titleWrapper}>
          <Text variant="title.1">Платеж не получен</Text>
          <Text color="description" variant="caption.2">
            Если вы все же оплатили, загрузите чек
          </Text>
        </div>
        <div className={styles.buttonContainer}>
          <Button onClick={() => router.push(`/order/${orderUuid}/dispute`)} color="gray">
            Загрузить чек
          </Button>
          {redirectUrl && <Button onClick={() => router.replace(redirectUrl)}>В магазин</Button>}
        </div>
      </div>
    </div>
  );
};
