import { captureException } from '@sentry/nextjs';
import { isAxiosError } from 'axios';

export class LogService {
  constructor(protected name: string) {}

  public error(err: unknown): void {
    captureException(err, {
      extra: {
        error: String(err),
        place: this.name,
        axiosErrorData: isAxiosError(err) ? JSON.stringify(err.response?.data) : undefined,
        axiosErrorStatus: isAxiosError(err) ? err.response?.status : undefined,
        errorJson: JSON.stringify(err),
      },
    });

    let error = String(err);

    if (isAxiosError(err)) {
      error = err.name + ' ' + err.response?.status + ' ' + JSON.stringify(err.response?.data);
    }

    console.log(`[${new Date().toLocaleString()} - ${this.name}]: ${error}`);
  }
}
