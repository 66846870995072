export enum OrderStatusesEnum {
  IN_PROGRESS = 1,
  ENTERED = 2,
  NOT_ENTERED = 3,
  EXECUTED = 4,
  NOT_EXECUTED = 5,
  UNKNOWN_ENTERED = 6,
  AWAITING = 7,
  WAITING_FOR_REFUND = 8,
  REFUNDED = 9,
  WAITING_FOR_BANK_SELECT = 12,
  CANCELED_BY_CUSTOMER = 11,
}
