import { CSSProperties, FC, PropsWithChildren } from 'react';
import cn from 'classnames';
import styles from './ContentBox.module.css';

interface Props {
  width?: string;
  gap?: string;
  className?: string;
  style?: CSSProperties;
  alignCenter?: boolean;
}

export const ContentBox: FC<Props & PropsWithChildren> = ({
  width = '550px',
  children,
  gap,
  className,
  style = {},
  alignCenter,
}) => {
  return (
    <div
      className={cn(styles.container, alignCenter && styles.alignCenter, className)}
      style={{ width: `min(${width}, 100%)`, gap, ...style }}
    >
      {children}
    </div>
  );
};
