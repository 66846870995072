import { FC } from 'react';
import styles from './ProgressBar.module.css';
import StepDoneIcon from '@/assets/stepDone.svg';
import StepNextIcon from '@/assets/stepNext.svg';
import { Text } from '@/ui/Text';
import cn from 'classnames';

interface Props {
  steps: string[];
  current: number;
  className?: string;
}

export const ProgressBar: FC<Props> = ({ steps, current, className }) => {
  return (
    <div className={cn(styles.container, className)}>
      {steps.map((stepName, index) => {
        if (index < current) {
          return (
            <>
              <StepDoneIcon key={index} />
              <div className={styles.doneLine} />
            </>
          );
        }

        if (index === current) {
          return (
            <div style={{ position: 'relative', display: 'flex' }} key={index}>
              <StepDoneIcon />
              <Text variant="caption.2" className={styles.stepName}>
                {stepName}
              </Text>
            </div>
          );
        }

        if (index > current) {
          return (
            <>
              <div className={styles.nextLine} />
              <StepNextIcon key={index} />
            </>
          );
        }
      })}
    </div>
  );
};
