import React from 'react';
import styles from './RequisitesWidgetWarning.module.css';
import { Text } from '@/ui';
import InfoIcon from '@/assets/info-icon.svg';

interface Props {}

export const RequisitesWidgetWarning: React.FC<Props> = () => {
  return (
    <div className={styles.container}>
      <Text variant="caption.1" color="description">
        Если вы не подтвердите платёж в течении 8 минут, то заявка будет отменена автоматически. Переводите только в
        указанный банк, иначе средства могут быть утеряны!
      </Text>
      <div className={styles.iconWrapper}>
        <InfoIcon />
      </div>
    </div>
  );
};
