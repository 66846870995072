import { Button, Text } from '@/ui';
import styles from '@/shared/styles/StatusWidgetStyles.module.css';
import ExclamationMark from '@/assets/exclamationMark.svg';
import { useRouter } from 'next/router';

interface Props {
  disputeId: string;
  redirectUrl?: string | null;
}

export const FileUploadedWidget: React.FC<Props> = ({ disputeId, redirectUrl }) => {
  const router = useRouter();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.imgContainer}>
          <ExclamationMark />
        </div>
        <div className={styles.titleWrapper}>
          <Text variant="title.1">Создано обращение с номером </Text>
          <Text variant="title.1">{disputeId}</Text>
        </div>
        <div className={styles.titleWrapper}>
          <Text color="description" variant="caption.2">
            Мы начали ручной поиск вашего платежа по данным из приложенного вами чека
          </Text>
        </div>
        {redirectUrl && (
          <div className={styles.buttonContainer}>
            <Button onClick={() => router.replace(redirectUrl)}>В магазин</Button>
          </div>
        )}
      </div>
    </div>
  );
};
