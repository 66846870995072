import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './SelectBankWidget.module.css';
import { BigIconButton, Button, ProgressBar, Text } from '@/ui';
import Image from 'next/image';
import ArrowLeftIcon from '@/assets/arrowLeft.svg';
import ArrowRightIcon from '@/assets/arrowRight.svg';
import Search from '@/assets/search.svg';
import Close from '@/assets/close.svg';
import { FetchPreorderConfigRequestResponse } from '@/server/api';
import { PaymentMethodEnum } from '@/shared/types/paymentMethod.enum';

interface Props {
  config: FetchPreorderConfigRequestResponse;
  onBankSelect: (bankId: number) => void;
  onBack: () => void;
}

export const SelectBankWidget: React.FC<Props> = ({ config, onBankSelect, onBack }) => {
  const [selectedBank, setSelectedBank] = useState<{ id: number; name: string }>();
  const [searchBank, setSearchBank] = useState<string>('');
  const [isSearchEnable, setIsSearchEnable] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const banksList = useMemo(() => {
    const list = config.requisites.find(({ type }) => type === PaymentMethodEnum.CARD);

    return list?.banks ?? [];
  }, [config]);

  useEffect(() => {
    if (isSearchEnable && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isSearchEnable]);

  const filteredBanks = useMemo(
    () => banksList.filter((bank) => bank.name.toLowerCase().includes(searchBank.toLowerCase())),
    [searchBank, banksList]
  );

  return (
    <div className={styles.container}>
      <ProgressBar current={1} steps={['Выбор способа', 'Выбор банка', 'Поиск реквизитов', 'Реквизиты']} />
      <div className={styles.bankContainer}>
        <div className={styles.header}>
          {!isSearchEnable && (
            <>
              <Text style={{ color: 'black', paddingBottom: 16 }} variant="title">
                Выберите банк для оплаты по карте
              </Text>
              <Search />
            </>
          )}
          {isSearchEnable && (
            <div className={styles.searchContainer}>
              <Search />
              <input
                ref={inputRef}
                placeholder="Поиск банка"
                value={searchBank}
                onChange={(e) => setSearchBank(e.target.value)}
                style={{ padding: '12px', width: '100%' }}
              />
              <Close
                onClick={() => {
                  setIsSearchEnable(!isSearchEnable);
                  setSearchBank('');
                }}
              />
            </div>
          )}
        </div>
        {filteredBanks.length === 0 ? (
          <div className={styles.noMatches}>Нет совпадений.</div>
        ) : (
          <div className={styles.bankItems}>
            {filteredBanks.map((el, index) => (
              <BigIconButton
                key={index}
                onClick={() => setSelectedBank(el)}
                icon={<Image alt="bank" src={el.image} width={60} height={60} style={{ objectFit: 'contain' }} />}
                selected={selectedBank?.id === el.id}
              >
                {el.name}
              </BigIconButton>
            ))}
          </div>
        )}
      </div>
      <div className={styles.buttonContainer}>
        <div className={styles.buttonWrapper}>
          <Button color="gray" onClick={onBack}>
            <ArrowLeftIcon fill="#fff" />
            Назад
          </Button>
          <Button onClick={() => onBankSelect(selectedBank!.id)}>
            Далее
            <ArrowRightIcon fill="#090f1f" />
          </Button>
        </div>
      </div>
    </div>
  );
};
