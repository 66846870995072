import { useState, useEffect, useRef } from 'react';

interface Options {
  onTimerEnd?: () => void;
  onTick?: (secondsLeft: number) => void;
}

export function useTimer(duration: number, options?: Options) {
  const [timeLeft, setTimeLeft] = useState(duration);
  const timerRef = useRef<NodeJS.Timer>(undefined);
  const timerCompleted = useRef(false);

  useEffect(() => {
    if (timeLeft > 0) {
      options?.onTick?.(timeLeft);
    }
  }, [timeLeft]);

  useEffect(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    setTimeLeft(duration);
    timerCompleted.current = false;

    timerRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current);

          if (!timerCompleted.current) {
            timerCompleted.current = true;
            options?.onTimerEnd?.();
          }

          return 0;
        }

        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timerRef.current);
  }, [duration]);

  return timeLeft;
}
