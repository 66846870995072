import { DetailedHTMLProps, FC, HTMLAttributes, PropsWithChildren } from 'react';
import cn from 'classnames';
import styles from './Text.module.css';

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  variant:
    | 'title'
    | 'title.1'
    | 'title.1.medium'
    | 'caption.1'
    | 'caption.2'
    | 'caption.1.semibold'
    | 'caption.1.medium'
    | 'counter';
  color?: 'primary' | 'primary.light' | 'description' | 'secondary';
  textAlign?: 'start' | 'center' | 'end';
}

export const Text: FC<Props & PropsWithChildren> = ({
  children,
  variant,
  color = 'primary',
  className,
  textAlign = 'center',
  ...props
}) => {
  return (
    <div
      className={cn(
        className,
        variant === 'title' && styles.title,
        variant === 'title.1' && styles.title1,
        variant === 'title.1.medium' && styles.title1Medium,
        variant === 'caption.1' && styles.caption1,
        variant === 'caption.2' && styles.caption2,
        variant === 'caption.1.semibold' && styles.caption1Semibold,
        variant === 'caption.1.medium' && styles.caption1Medium,
        variant === 'counter' && styles.counter,
        color === 'primary' && styles.colorPrimary,
        color === 'primary.light' && styles.colorPrimaryLight,
        color === 'description' && styles.colorDescription,
        color === 'secondary' && styles.colorSecondary,
        textAlign === 'center' && styles.textAlignCenter,
        textAlign === 'start' && styles.textAlignStart,
        textAlign === 'end' && styles.textAlignEnd
      )}
      {...props}
    >
      {children}
    </div>
  );
};
